<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        Write the complete/total ionic equation as well as the net ionic equation for the chemical
        reaction described below. Note that any product not in the solid phase can be assumed to be
        aqueous (aq).
      </p>

      <p class="pl-10 mb-6">
        <stemble-latex content="$\text{Aqueous lead(II) nitrate plus potassium iodide}$" />
      </p>
      <p>
        <b>Total Ionic Equation:</b>
      </p>
      <chemical-notation-input
        v-model="inputs.totalIonicA"
        dense
        :show-note="false"
        class="mb-4"
        :disabled="!allowEditing"
      />
      <p>
        <b>Net Ionic Equation:</b>
      </p>
      <chemical-notation-input
        v-model="inputs.netIonicA"
        dense
        :show-note="false"
        class="mb-7"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question226a',
  components: {
    ChemicalNotationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        totalIonicA: null,
        netIonicA: null,
      },
    };
  },
};
</script>
